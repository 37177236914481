import React from 'react'
import ProductCard from '../common/ProductCard'
import Testimonial from '../common/Testimonial'
import SectionHeader from '../common/SectionHeader'
import Partners from '../common/Partners'

export default ({
  title,
  subtitle,
  diySurvey,
  researchServices,
  preview,
  toggleHubspotModalResearchConsultation,
  toggleHubspotModalRequestQuote,
}) => {
  return (
    <div className="productContainer">
      <div className="mainContent">
        <SectionHeader title={title} subtitle={subtitle} />
        <div className="columns is-multiline is-variable is-6-tablet is-0-mobile topMargin">
          <ProductCard
            title={researchServices.title}
            details={researchServices.details}
            price={300}
            buttonText={researchServices.buttonText}
            previewImage={researchServices.image}
            key="consultant"
            onClick={toggleHubspotModalResearchConsultation}
            section="customResearch"
            long
          />
          <ProductCard
            title={diySurvey.title}
            details={diySurvey.details}
            price={150}
            buttonText={diySurvey.buttonText}
            previewImage={diySurvey.image}
            key="custom"
            onClick={toggleHubspotModalRequestQuote}
            section="customResearch"
          />
        </div>
      </div>
      {!preview && <Testimonial />}
      {!preview && <Partners />}
    </div>
  )
}
