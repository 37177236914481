import React from 'react'
import { connect } from 'react-redux'
import LoggedOutTemplate from './LoggedOutTemplate'
import {
  toggleHubspotModalRequestQuote,
  toggleHubspotModalResearchConsultation,
} from '../../../redux/actions/modals'

class CustomResearchLoggedOut extends React.Component {
  render() {
    return <LoggedOutTemplate {...this.props} />
  }
}

export default connect(() => ({}), {
  toggleHubspotModalRequestQuote,
  toggleHubspotModalResearchConsultation,
})(CustomResearchLoggedOut)
