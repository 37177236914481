import React from 'react'
import PreviewCompatibleImage from '../../PreviewCompatibleImage'
import { StaticQuery, graphql } from 'gatsby'
import { isString } from 'lodash'

export default function Partners() {
  return (
    <StaticQuery
      query={graphql`
        query PartnerQuery {
          markdownRemark(frontmatter: { pulseKey: { eq: "partners" } }) {
            frontmatter {
              partners {
                image {
                  childImageSharp {
                    fixed(
                      width: 94
                      quality: 80
                      fit: CONTAIN
                      background: "rgba(255, 255, 255, 1)"
                    ) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          markdownRemark: {
            frontmatter: { partners },
          },
        } = data
        return <PartnerTemplate partners={partners} />
      }}
    />
  )
}

export const PartnerTemplate = ({ partners, preview }) => {
  return (
    <div className="partners" style={preview ? { backgroundColor: '#1f2e3d' } : {}}>
      <h1>Our Partners</h1>
      <div className="logoRow">
        {partners.map((partner, i) => (
          <div className="logoContainer" style={isString(partner.image) ? { width: 154 } : {}}>
            <PreviewCompatibleImage
              imageInfo={!partner.image || isString(partner.image) ? partner : partner.image}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
