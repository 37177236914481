import React from 'react'
import { graphql } from 'gatsby'

import LoggedOut from '../components/marketplace/research/LoggedOut'
import MarketplaceLayout from '../components/marketplace/MarketplaceLayout'

export default class ResearchPage extends React.Component {
  render() {
    // return isSignedIn() ? <LoggedIn /> : <LoggedOut />
    // const { data } = this.props;
    // const DIYSurveyImage = data.wideImages.childImageSharp.fluid;
    const { data } = this.props
    const { frontmatter } = data.markdownRemark
    return (
      <MarketplaceLayout active="research">
        <LoggedOut
          title={frontmatter.title}
          subtitle={frontmatter.subtitle}
          diySurvey={frontmatter.diySurvey}
          researchServices={frontmatter.researchServices}
        />
      </MarketplaceLayout>
    )
  }
}

export const researchPageQuery = graphql`
  query ResearchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        diySurvey {
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, maxHeight: 683, quality: 80, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          details
          buttonText
          title
        }
        researchServices {
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, maxHeight: 683, quality: 80, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          details
          buttonText
          title
        }
      }
    }
  }
`
