import React from 'react'
import { Link, navigate } from 'gatsby'
import mixpanel from 'mixpanel-browser'
import Layout from '../Layout'
import { isSignedIn } from '../../lib/auth'

export default class TabsContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      fallbackTab: 'research',
    }
  }
  componentDidMount() {
    if (window.location.hostname === 'localhost') {
      mixpanel.init('ddf52ed93dbdca563d9e41cb72f87dd6')
    } else {
      mixpanel.init('6f24e5d7e84c4e367451d45bd7123131')
      mixpanel.set_config({ disable_notifications: true })
    }

    mixpanel.track('Marketplace Tab Visit', {
      tab: window.location.pathname.split('/')[2],
    })

    this.setState({ fallbackTab: window.location.pathname.split('/')[2] })

    if (this.props.requireAuth && !isSignedIn()) {
      navigate('/marketplace/insights', { replace: true })
    }
  }
  render() {
    const { children, active, noFooter } = this.props
    const activeTab = active

    return (
      <Layout noFooter={noFooter}>
        <div className="marketingTabsContainer is-hidden-mobile">
          {isSignedIn() ? (
            <div className="links-container long">
              <Link
                to="/marketplace/brand/competitors"
                id="brandTab"
                className={`link ${activeTab === 'brand' ? 'active' : ''}`}
              >
                Brand Tracker
              </Link>
              <Link
                to="/marketplace/playbooks"
                id="playbooksTab"
                className={`link ${activeTab === 'playbooks' ? 'active' : ''}`}
              >
                Playbooks
              </Link>
              <p
                id="researchTab"
                className={`link ${activeTab === 'research' ? 'active' : ''} disabled`}
              >
                Targeting
              </p>
              <p
                id="researchTab"
                className={`link ${activeTab === 'research' ? 'active' : ''} disabled`}
              >
                University Explorer
              </p>
            </div>
          ) : (
            <div className="links-container">
              <Link
                to="/marketplace/research"
                id="researchTab"
                className={`link ${activeTab === 'research' ? 'active' : ''}`}
              >
                Custom Research
              </Link>
              <Link
                to="/marketplace/insights"
                id="insightsTab"
                className={`link ${activeTab === 'insights' ? 'active' : ''}`}
              >
                Insights
              </Link>
              <Link
                to="/marketplace/solutions"
                id="solutionsTab"
                className={`link ${activeTab === 'solutions' ? 'active' : ''}`}
              >
                Solutions
              </Link>
            </div>
          )}
        </div>
        {children}
      </Layout>
    )
  }
}
