import React from 'react'
import defaultTestimonial from '../../../img/testimonial.jpg'

const DEFAULT_TESTIMONIAL = {
  image: defaultTestimonial,
  description:
    '"<span class="green">College Pulse</span> is an outstanding research partner – ' +
    'highly <span class="green">responsive</span> and <span class="green">collaborative</span> from start to finish.' +
    ' We have findings in hand in a matter of days and an insightful, visually engaging report within weeks. ' +
    'Thank you for designing efficiency and quality into your offering."',
  title:
    'Evette Alexander, Director of Learning & Impact, <span class="green">Knight Foundation</span>',
}

export default function Testimonial({
  description = DEFAULT_TESTIMONIAL.description,
  userImage = DEFAULT_TESTIMONIAL.image,
  userTitle = DEFAULT_TESTIMONIAL.title,
}) {
  return (
    <div className="testimonial columns">
      <img src={userImage} className="is-hidden-mobile is-one-fifth" alt={userTitle} />
      <div className="text column is-full-mobile is-two-fifths-tablet">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="mainText is-family-primary is-italic is-size-6"
        />
        <div
          dangerouslySetInnerHTML={{ __html: userTitle }}
          className="subText is-hidden-mobile is-family-primary is-size-6"
        />
        <div className="is-hidden-tablet is-flex" style={{ marginTop: 10, alignItems: 'center' }}>
          <img src={userImage} alt={userTitle} />
          <div
            dangerouslySetInnerHTML={{ __html: userTitle }}
            className="subText is-family-primary is-size-6"
          />
        </div>
      </div>
    </div>
  )
}
